import React from 'react';

import CategoryPage from '../../components/CategoryPage';

const NewBorn = () => (
  <CategoryPage
    title="Recém Nascido"
    totalItems={14}
    s3Path={`portfolio/new-born/`}
    internalPath={`/recem-nascido/`}
    nextPath={`/casamento`}
    nextLabel="Casamento"
    prevPath={`/gravida`}
    prevLabel="Grávida"
  />
)

export default NewBorn;
